import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { motion, AnimatePresence } from 'framer-motion'
import { useMount, useUnmount } from 'react-use';
import { Element, scroller } from 'react-scroll';
import { withHeader } from '../components/Header/HeaderContext';

import { Block, PostIndex } from '../components'

import { Layout } from '../components'
import { container, padding, bgImage, pagePaddingTopIndex } from '../styles/global'
import { parseACF } from '../utils'
import { legacyHeading2, subheading } from '../styles/type'
import { coolGrey } from '../styles/colours'

const Journal = (props) => {
	const { indexVisible, indexActive, setHeaderContext } = props;
	const graphData = useStaticQuery(query);
	const data = props.previewData || parseACF(graphData, 'allWordpressInfopages')
	const newsCats = graphData.allWordpressNewscats.nodes;
	const news = graphData.allWordpressNews.nodes;
	
	useMount(() => {
		setTimeout(() => {
			setHeaderContext({indexVisible: true})
		}, 100);
	})

	useUnmount(() => {
		setTimeout(() => {
			setHeaderContext({indexVisible: false})
		}, 1);
	})

	const renderGridBlocks = (blocks) => {
        if (!blocks) return;
        
		return blocks.map((block, i) => {  
			return (
				<Block
					layout={'grid_item'}
					itemType={'news'}
					display={true}
                    key={i}
					{...block}
				/>
			)
		})
	}

	const getIndexFilters = () => {
		const filters = [
			...newsCats,
			{
				title: 'Chronological',
				type: 'date',
				slug: 'date',
				isSorting: true,
			},
			{
				title: 'A – Z',
				type: 'name',
				slug: 'name',
				isSorting: true,
			},
		]

		return filters
	}

	const renderIndex = () => {
		return (
            <PostIndex
				key={'postIndex'}
				variants={variants}
				posts={news}
				postType={'news'}
				filters={getIndexFilters()}
				pageTheme={'dark'}
				breadcrumb={'Journal'}
			/>
		)
	}
	
	return (
		<Layout
			meta={data.seo}
			footerTheme={'journal'}
		>
			<Wrapper>
				<AnimatePresence
					exitBeforeEnter={true}
				>
					{renderIndex()}
				</AnimatePresence>
			</Wrapper>
		</Layout>	
	)
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
	${container}
	${padding}

	display: flex;
	flex-direction: column;
`

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${coolGrey};
	// ${pagePaddingTopIndex};
`

// Grid

const Grid = styled(motion.div)`
	width: 100%;
`
// Animated Switch

const duration = 0.35;

const variants = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
		transition: {
			duration: duration,
			delay: duration,
			when: 'beforeChildren',
			delayChildren: 2,
			staggerChildren: 2
		},
	},
	exit: {
		opacity: 0,
		transition: { duration: duration },
	},
}

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "news" } }) {
            nodes {
                acf_json
            }
        }

		allWordpressNewscats {
			nodes {
			  slug
			  title
			}
		}

		allWordpressNews {
			nodes {
				title
				slug
				acf_json
				publishedDateUnix
				
				primary_cat {
					name
					slug
				}

				cats {
					name
					slug
				}
			}
		}
    }
`

export default withHeader(Journal)